<template>
  <div class="advance-filter">
    <div class="advance-filter">
      <el-popover placement="bottom" trigger="click" title="高级筛选">
        <div class="filters">
          <div
            v-if="topSectionList && topSectionList.length"
            :class="[
              'filter-item',
              $route.query.subjectId === '0' ? 'ban' : '',
            ]"
          >
            <div :class="['note']" @click="topSectionChange">
              {{ topSectionChecked ? "取消选择" : "全选" }}
              <!-- <img src="@/assets/imgs/academic/open-eye.png" alt="" /> -->
              <!-- <img src="@/assets/imgs/academic/close-eye.png" alt="" />
              <img src="@/assets/imgs/academic/ban.png" alt="" /> -->
            </div>
            <div class="filter-item-title">前N名</div>
            <div @click.stop="">
              <el-checkbox-group
                v-model="topSections"
                :disabled="
                  $route.query.subjectId === '0' || !topSectionList.length
                "
                @change="topSectionsChange"
              >
                <el-checkbox
                  v-for="(item, index) in topSectionList"
                  :key="index"
                  :label="item"
                >
                  <span>{{ item.name }}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div v-if="ratioList && ratioList.length" :class="['filter-item']">
            <div :class="['note']" @click="otherChange">
              <!-- <img src="@/assets/imgs/academic/open-eye.png" alt="" /> -->
              {{ otherChecked ? "取消选择" : "全选" }}
              <!-- <img src="@/assets/imgs/academic/close-eye.png" alt="" />
              <img src="@/assets/imgs/academic/ban.png" alt="" /> -->
            </div>
            <div class="filter-item-title">基础指标</div>
            <div>
              <el-checkbox-group
                v-model="ratios"
                :disabled="!ratioList.length"
                @change="ratiosChange"
              >
                <el-checkbox
                  v-for="(item, index) in ratioList"
                  :key="index"
                  :label="item"
                >
                  <span>{{ item.name }}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <el-button slot="reference" type="text">高级筛选</el-button>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ratioList: {
      type: Array,
      default: () => [],
    },
    topSectionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkList: [],
      topSectionChecked: true,
      otherChecked: true,
      topSections: [],
      ratios: [],
      ratiosRatioDis: false,
      ratiosTotalDis: false,
      topSectionTotalDis: false,
      topSectionRatioDis: false,
      ratioProportion: true,
      ratioTotal: true,
      topSectionTotal: false,
      topSectionRatio: false,
      oldRatios: [],
      oldTopSections: [],
    };
  },
  mounted() {
    this.ratios = [...this.ratioList];
    this.otherChecked = this.ratioList && this.ratioList.length > 0;
    this.topSectionChecked =
      this.topSectionList && this.topSectionList.length > 0;
    if (this.$route.query.subjectId !== "0") {
      this.topSections = [...this.topSectionList];
    }
  },
  methods: {
    setAllRatio(ratioTotal, ratioProportion) {
      if (ratioTotal || ratioProportion) {
        this.ratios = this.ratioList;
        this.otherChecked = true;
      } else {
        this.ratios = [];
        this.otherChecked = false;
      }
    },
    setAllTopSections(topSectionTotal, topSectionProportion) {
      if (topSectionTotal || topSectionProportion) {
        this.topSections = this.topSectionList;
        this.topSectionChecked = true;
      } else {
        this.topSections = [];
        this.topSectionChecked = false;
      }
    },
    ratiosChange() {
      this.otherChecked = this.ratios.length > 0;
      if (this.ratios.length) {
        this.oldRatios = JSON.parse(JSON.stringify(this.ratios));
      }
      this.$emit("ratiosChange", this.ratios);
    },
    otherChange() {
      this.otherChecked = !this.otherChecked;
      this.ratios = this.otherChecked ? this.ratioList : [];
      this.$emit("otherCheckedChange", this.otherChecked);
    },
    topSectionsChange() {
      this.topSectionChecked = this.topSections.length > 0;
      if (this.topSections.length) {
        this.oldTopSections = JSON.parse(JSON.stringify(this.topSections));
      }
      this.$emit("topSectionChange", this.topSections);
    },
    topSectionTotalChange(val) {
      this.$parent.visibleForm.topSectionTotal = val;
    },
    topSectionRatioChange(val) {
      this.$parent.visibleForm.topSectionRatio = val;
    },

    topSectionChange() {
      this.topSectionChecked = !this.topSectionChecked;
      this.topSections = this.topSectionChecked ? this.topSectionList : [];
      this.$emit("topSectionCheckedChange", this.topSectionChecked);
    },
  },
};
</script>
<style lang="scss" scoped>
.filters {
  .filter-item {
    width: 432px;
    border: 1px solid #dadbe0;
    background: #fafcff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
    position: relative;
    &:hover {
      border-color: #3f87f4;
      background: #fafcff;
      .note {
        background: #2474ed;
        color: #ffffff;
      }
    }
    .filter-item-title {
      color: #0a1119;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 18px;
      user-select: none;
    }

    .el-checkbox {
      margin-right: 18px;
      margin-bottom: 18px;
    }
    .detail {
      padding-top: 18px;
      box-sizing: border-box;
      border-top: 1px solid #d5d6db;
    }
    .note {
      position: absolute;
      right: 0;
      cursor: pointer;
      top: 0;
      background: #dadbe0;
      padding: 5px 10px;
      user-select: none;
      // width: 24px;
      // height: 24px;
      // line-height: 12px;
      text-align: center;
      // background: #2474ED;
      // background-image: linear-gradient(
      //   225deg,
      //   #bfc0c5 50%,
      //   rgba(255, 255, 255, 0) 50%
      // );
      // img {
      //   transform: translateX(50%);
      // }
    }
  }
  .filter-item + .filter-item {
    margin-top: 18px;
  }
  .active {
    border-color: #3f87f4;
    background: #fafcff;
    .note {
      position: absolute;
      right: 0;
      user-select: none;
      color: #ffffff;
      user-select: none;
      cursor: pointer;
      top: 0;
      // width: 24px;
      // height: 24px;
      // line-height: 12px;
      text-align: center;
      background: #2474ed;
      padding: 5px 10px;

      // background-image: linear-gradient(
      //   225deg,
      //   #3f87f4 50%,
      //   rgba(255, 255, 255, 0) 50%
      // );
      img {
        transform: translateX(50%);
      }
    }
  }
  .ban {
    background-color: #f4f5f7;
    user-select: none;
    border-color: transparent;
    cursor: not-allowed;
    pointer-events: none !important;
    .note {
      position: absolute;
      right: 0;
      cursor: not-allowed;
      top: 0;
      // width: 24px;
      // height: 24px;
      // line-height: 12px;
      text-align: center;
      padding: 5px 10px;
      // background-image: linear-gradient(
      //   225deg,
      //   #d5d6db 50%,
      //   rgba(255, 255, 255, 0) 50%
      // );
      // img {
      //   transform: translateX(50%);
      // }
    }
  }
}
</style>
