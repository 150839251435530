<template>
  <div v-loading="" class="GradeRank">
    <detailsHeader hidden-back />
    <div class="filter-index">
      <div class="left">
        <div class="category">
          <div class="category-title">教学</div>
          <el-checkbox
            v-model="visibleForm.classNum"
            :disabled="$route.query.subjectId === '0'"
            >{{ getClassName() }}</el-checkbox
          >
          <el-checkbox v-model="visibleForm.teacherName">
            <span>{{
              $route.query.subjectId === "0" ? "班主任" : "任课老师"
            }}</span>
          </el-checkbox>
        </div>
        <div class="category">
          <div class="category-title">比率</div>
          <el-checkbox v-model="visibleForm.deviationFromMean"
            >离均差</el-checkbox
          >
          <el-checkbox v-model="visibleForm.overAverageRate"
            >超均率</el-checkbox
          >
        </div>
        <div class="category">
          <div class="category-title">分数</div>
          <el-checkbox v-model="visibleForm.lowestScore">最低分</el-checkbox>
          <el-checkbox v-model="visibleForm.highestScore">最高分 </el-checkbox>
        </div>
        <div class="category">
          <div class="category-title">统计</div>
          <el-checkbox v-model="visibleForm.rangeScore">全距</el-checkbox>
          <el-checkbox v-model="visibleForm.median">中位数</el-checkbox>
          <el-checkbox v-model="visibleForm.standardDeviation"
            >标准差</el-checkbox
          >
          <el-checkbox v-model="visibleForm.coefficientOfVariation"
            >差异系数
          </el-checkbox>
        </div>
        <div class="category">
          <div class="category-title">前N名</div>
          <el-checkbox
            v-model="visibleForm.topSectionTotal"
            :disabled="$route.query.subjectId === '0'"
            @change="(val) => checkChange(val, 'topSectionTotal')"
          >
            <el-tooltip
              :disabled="$route.query.subjectId !== '0'"
              content="仅适用于单科"
              placement="bottom"
              effect="dark"
            >
              <span>人 数</span>
            </el-tooltip>
          </el-checkbox>
          <el-checkbox
            v-model="visibleForm.topSectionProportion"
            :disabled="$route.query.subjectId === '0'"
            @change="(val) => checkChange(val, 'topSectionProportion')"
          >
            <el-tooltip
              :disabled="$route.query.subjectId !== '0'"
              content="仅适用于单科"
              placement="bottom"
              effect="dark"
            >
              <span>比 率</span>
            </el-tooltip></el-checkbox
          >
        </div>
        <div class="category">
          <div class="category-title">基础指标</div>
          <el-checkbox
            v-model="visibleForm.ratioTotal"
            @change="(val) => checkChange(val, 'ratioTotal')"
            >人数</el-checkbox
          >
          <el-checkbox
            v-model="visibleForm.ratioProportion"
            @change="(val) => checkChange(val, 'ratioProportion')"
            >比率</el-checkbox
          >
        </div>
        <advance-filter
          :key="tokenKey"
          ref="advanceFilter"
          :ratio-list="ratioList"
          :top-section-list="topSectionList"
          style="margin-left: 36px"
          @ratiosChange="ratiosChange"
          @topSectionCheckedChange="topSectionCheckedChange"
          @otherCheckedChange="otherCheckedChange"
          @topSectionChange="topSectionChange"
        />
      </div>
      <stat-params />
    </div>
    <div class="table-content">
      <div class="operate">
        <div class="title">各项指标对比 - {{ $route.query.subjectName }}</div>
        <div class="operate-box">
          <el-input
            v-model="search.input"
            style="width: 180px; margin-right: 18px"
            suffix-icon="el-icon-search"
            placeholder="搜索班级"
            @input="inputChange"
          ></el-input>
          <el-dropdown @command="dropdownChange">
            <el-button type="primary">
              导出为Excel<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="current">当前报表</el-dropdown-item>
              <el-dropdown-item command="detail">详细报表</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <resEleccomb-list
        v-if="$route.query.subjectId === '0'"
        :res-eleccomb-list="resEleccombList"
        :res-eleccomb-id="resEleccombId"
        @change="handleClick"
      />
      <el-table
        ref="elTable"
        :data="tableData"
        border
        style="width: 100%; margin-bottom: 18px"
      >
        <el-table-column
          v-if="visibleForm.classNum"
          prop="classNum"
          :label="getClassName()"
          :filters="filterData"
          :filter-method="filterHandler"
          column-key="classNum"
          align="center"
          width="110"
        >
          <template #header>
            {{ getClassName() }}
            <img src="@/assets/newExam/sizer.svg" alt=""
          /></template>
        </el-table-column>
        <el-table-column
          v-if="visibleForm.teacherName"
          prop="teacherName"
          :label="$route.query.subjectId === '0' ? '班主任' : '任课老师'"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="statTotal"
          width="100"
          label="统计人数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="averageScore"
          :width="
            ['1', '2', '3', '4', '8'].includes($route.query.subjectId)
              ? 120
              : 100
          "
          :label="
            ['1', '2', '3', '4', '8'].includes($route.query.subjectId)
              ? '原始分均分'
              : '赋分均分'
          "
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.deviationFromMean"
          prop="deviationFromMean"
          label="离均差"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.overAverageRate"
          prop="overAverageRate"
          label="超均率"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.highestScore"
          prop="highestScore"
          label="最高分"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.lowestScore"
          prop="lowestScore"
          label="最低分"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.rangeScore"
          prop="rangeScore"
          width="120"
          label="全距"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.median"
          prop="median"
          label="中位数"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.standardDeviation"
          prop="standardDeviation"
          label="标准差"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.coefficientOfVariation"
          prop="coefficientOfVariation"
          label="差异系数"
          width="100"
          align="center"
        >
        </el-table-column>
        <template
          v-if="
            $route.query.subjectId !== '0' &&
            (visibleForm.topSectionProportion || visibleForm.topSectionTotal) &&
            topSectionChecked
          "
        >
          <el-table-column label="前N名" align="center">
            <el-table-column
              v-for="(item, index) in topSectionHeader"
              :key="'topSectionHeader' + index"
              :label="item.name"
              width="160"
              align="center"
            >
              <el-table-column
                v-if="visibleForm.topSectionTotal"
                label="人数"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span
                    :class="row.topSections[index].total ? 'allowClick' : ''"
                    @click="toDetails(row, index)"
                    >{{ row.topSections[index].total }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="visibleForm.topSectionProportion"
                label="比率"
                align="center"
              >
                <template v-if="row.topSections.length" slot-scope="{ row }">
                  {{ row.topSections[index].proportion }}
                  <span v-if="row.topSections[index].proportion">%</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table-column>
        </template>
        <template
          v-if="
            (visibleForm.ratioTotal || visibleForm.ratioProportion) &&
            ratioChecked
          "
        >
          <el-table-column label="基础指标" align="center">
            <el-table-column
              v-for="(item, index) in ratiosHeader"
              :key="'ratioList' + index"
              min-width="160"
              :label="item.name"
              align="center"
            >
              <el-table-column
                v-if="visibleForm.ratioTotal"
                label="人数"
                align="center"
              >
                <template slot-scope="{ row }">{{
                  row.ratios[index].total
                }}</template>
              </el-table-column>
              <el-table-column
                v-if="visibleForm.ratioProportion"
                label="比率"
                align="center"
              >
                <template slot-scope="{ row }"
                  >{{ row.ratios[index].proportion }}
                  <span v-if="row.ratios[index].proportion">%</span></template
                >
              </el-table-column>
            </el-table-column>
          </el-table-column>
        </template>
      </el-table>
      <!-- <div class="pagination">
        <el-pagination
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div> -->
      <chart :height="500" :data="criticalAnalyseDataOptions" />
    </div>
    <el-dialog
      title="导出详细榜单"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="export-box">
        <div class="export-box-title">选择更多数据项</div>
      </div>
      <div class="option">
        <div class="option-title">教学</div>
        <div class="checks">
          <el-checkbox
            v-model="checked"
            :true-label="1"
            :false-label="0"
            disabled
          >
            教学班级
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expTeacherName"
            :true-label="1"
            :false-label="0"
          >
            {{ $route.query.subjectId === "0" ? "班主任" : "任课教师" }}
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">比率</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expDeviationFromMean"
            :true-label="1"
            :false-label="0"
          >
            离均差
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expOverAverageRate"
            :true-label="1"
            :false-label="0"
          >
            超均率
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">分数</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expLowestScore"
            :true-label="1"
            :false-label="0"
          >
            最低分
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expHighestScore"
            :true-label="1"
            :false-label="0"
          >
            最高分
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">统计</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expRangeScore"
            :true-label="1"
            :false-label="0"
          >
            全距
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expMedian"
            :true-label="1"
            :false-label="0"
          >
            中位数
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expStandardDeviation"
            :true-label="1"
            :false-label="0"
          >
            标准差
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expCoefficientOfVariation"
            :true-label="1"
            :false-label="0"
          >
            差异系数
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">前N名</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expTopSectionTotal"
            :disabled="$route.query.subjectId === '0'"
            :true-label="1"
            :false-label="0"
          >
            <el-tooltip
              :disabled="$route.query.subjectId !== '0'"
              content="仅适用于单科"
              placement="bottom"
              effect="dark"
            >
              <span>人 数</span>
            </el-tooltip>
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expTopSectionProportion"
            :disabled="$route.query.subjectId === '0'"
            :true-label="1"
            :false-label="0"
          >
            <el-tooltip
              :disabled="$route.query.subjectId !== '0'"
              content="仅适用于单科"
              placement="bottom"
              effect="dark"
            >
              <span>比 率</span>
            </el-tooltip>
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">基础指标</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expRatioTotal"
            :true-label="1"
            :false-label="0"
          >
            人数
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expRatioProportion"
            :true-label="1"
            :false-label="0"
          >
            比率
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportDetail">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import detailsHeader from "@/components/detailsHeader.vue";
import advanceFilter from "./components/AdvanceFilter.vue";
import StatParams from "./components/StatParams.vue";
import { getObj } from "@/core/api/exam/exam";
import { getstateleccomboptionV2 } from "@/core/api/academic/common";
import {
  indicatorcompare,
  exportindicatorcompare,
} from "@/core/api/academic/onceExam";
import chart from "../components/chart.vue";
import resEleccombList from "./components/resEleccombList.vue";
export default {
  components: {
    detailsHeader,
    advanceFilter,
    StatParams,
    resEleccombList,
    chart,
  },
  data() {
    return {
      checked: 1,
      value: true,
      examInformation: null,
      search: {
        current: 1,
        size: 20,
      },
      total: 0,
      resEleccombId: 1,
      filterData: [],
      tableData: [],
      ratioList: [],
      topSectionList: [],
      visibleForm: {
        classNum: true,
        teacherName: true,
        highestScore: true,
        lowestScore: true,
        median: true,
        overAverageRate: true,
        rangeScore: true,
        standardDeviation: true,
        ratioProportion: true,
        ratioTotal: true,
        topSectionTotal: true,
        topSectionProportion: true,
        coefficientOfVariation: true,
        deviationFromMean: true,
      },
      dialogVisible: false,
      resEleccombList: [],
      ratiosHeader: [],
      topSectionHeader: [],
      tokenKey: Math.random(),
      topSectionChecked: true,
      ratioChecked: true,
      criticalAnalyseDataOptions: {},
      exportForm: {
        expCoefficientOfVariation: 1,
        expDeviationFromMean: 1,
        expHighestScore: 1,
        expLowestScore: 1,
        expMedian: 1,
        expOverAverageRate: 1,
        expRangeScore: 1,
        expRatioProportion: 1,
        expRatioTotal: 1,
        expStandardDeviation: 1,
        expTeacherName: 1,
        expTopSectionProportion: 1,
        expTopSectionTotal: 1,
      },
    };
  },
  mounted() {
    this.getExamObj();
    if (this.$route.query.subjectId === "0") {
      // this.visibleForm.teacherName = false;
      this.visibleForm.topSectionProportion = false;
      this.visibleForm.topSectionTotal = false;
      // this.$refs.advanceFilter.banTopSecton();
    } else {
      // this.$refs.advanceFilter.openTopSection();
    }
  },
  methods: {
    toDetails(row, index) {
      if (row.topSections[index].total > 0) {
        this.$router.push({
          path: "/academic/once/classTopTotal",
          query: {
            id: this.$route.query.statId,
            topSectionId: row.topSections[index].topSectionId,
            examName: this.$route.query.examName,
            subjectName: this.$route.query.subjectName,
            topSectionName: row.topSections[index].name,
            subjectId: this.$route.query.subjectId,
            classNum: row.classNum,
            type: this.$route.query.subjectId == 0 ? "total" : "single",
          },
        });
      }
    },
    inputChange() {
      this.search.current = 1;
      this.getData();
    },
    getClassName() {
      const { subjectId } = this.$route.query;
      if (["0"].includes(subjectId)) {
        return "行政班级";
      } else {
        return "教学班级";
      }
    },
    async exportDetail() {
      const { statId, subjectId } = this.$route.query;
      const res = await exportindicatorcompare({
        statId: Number(statId),
        subjectId: subjectId,
        resEleccombId: this.resEleccombId,
        ...this.exportForm,
        ...this.search,
      });
      this.dialogVisible = false;
      if (res.msg) {
        this.$message({
          type: "warning",
          message: res.msg,
          showClose: true,
        });
      } else {
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name: res.data.data.name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
      }
    },
    visibleCate(subjectId) {
      let id = subjectId;
      if (typeof id === "number") id = id.toString();
      let hasPhysics = this.examInformation.subjectIds.indexOf(subjectId) != -1;
      let current = ["0", "1", "2", "3", "4", "8"].includes(
        this.$route.query.subjectId
      );
      let isMustChoose =
        ["4", "8"].includes(subjectId) && id == this.$route.query.subjectId;
      if (["0", "1", "2", "3"].includes(this.$route.query.subjectId)) {
        return 1;
      } else {
        if (!isMustChoose) {
          return 0;
        } else if (hasPhysics && current) {
          return 1;
        } else {
          return 0;
        }
      }
    },
    ratiosChange(val) {
      if (val.length) {
        if (!this.visibleForm.ratioTotal && !this.visibleForm.ratioProportion) {
          this.visibleForm.ratioTotal = this.visibleForm.ratioProportion = true;
        }
      } else {
        this.visibleForm.ratioTotal = this.visibleForm.ratioProportion = false;
      }

      this.ratiosHeader = val;
    },
    topSectionCheckedChange(val) {
      this.visibleForm.topSectionTotal = val;
      this.visibleForm.topSectionProportion = val;
    },
    otherCheckedChange(val) {
      this.visibleForm.ratioTotal = val;
      this.visibleForm.ratioProportion = val;
    },
    topSectionChange(val) {
      if (val.length) {
        if (
          !this.visibleForm.topSectionTotal &&
          !this.visibleForm.topSectionProportion
        ) {
          this.visibleForm.topSectionTotal =
            this.visibleForm.topSectionProportion = true;
        }
      } else {
        this.visibleForm.topSectionTotal =
          this.visibleForm.topSectionProportion = false;
      }
      this.topSectionHeader = val;
    },
    checkChange(val, feild) {
      if (["ratioTotal", "ratioProportion"].includes(feild)) {
        const { ratioTotal, ratioProportion } = this.visibleForm;
        this.$refs.advanceFilter.setAllRatio(ratioTotal, ratioProportion);
        if (ratioTotal || ratioProportion) {
          this.ratiosHeader = this.ratioList;
        }
      }
      if (["topSectionTotal", "topSectionProportion"].includes(feild)) {
        const { topSectionTotal, topSectionProportion } = this.visibleForm;
        if (topSectionTotal || topSectionProportion) {
          this.topSectionHeader = this.topSectionList;
        }
        this.$refs.advanceFilter.setAllTopSections(
          topSectionTotal,
          topSectionProportion
        );
      }
      this.$forceUpdate();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async getGroup() {
      const { statId, subjectId } = this.$route.query;
      const res = await getstateleccomboptionV2({
        id: statId,
        subjectId: subjectId == "0" ? null : subjectId,
        eleccateId: 0,
        hasCate: 1,
      });
      this.resEleccombList = res.data.data;
      if (res.data.data.length) {
        let length = res.data.data.length;
        this.resEleccombId = length >= 2 ? 0 : res.data.data[0].id;
        // this.resEleccombName = length >= 2 ? "全部" : res.data.data[0].name;
        this.getData();
      }
    },
    async getData() {
      this.loading = true;
      try {
        const res = await indicatorcompare({
          ...this.search,
          resEleccombId: this.resEleccombId,
          statId: this.$route.query.statId,
          subjectId: this.$route.query.subjectId,
        });
        this.filterData = res.data.data.map((item) => ({
          text: item.classNum,
          value: item.classNum,
        }));
        this.tableData = res.data.data;
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
        // this.total = res.data.data.total;
        if (res.data.data.length) {
          this.ratioList = this.ratiosHeader = res.data.data[0].ratios;
          this.topSectionList = this.topSectionHeader =
            res.data.data[0].topSections;
          this.tokenKey = Math.random();
          const chartRatio =
            res.data.data.length && res.data.data[0].chartRatios;
          this.criticalAnalyseDataOptions = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#000",
                },
              },
            },
            legend: { itemGap: 30 },
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.classNum),
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            color: ["#5B8FF9", "#FF8C68", "#5D7092", "#F6BD16"],
            yAxis: [
              {
                type: "value",
                axisTick: { show: false },
                splitLine: { show: true },
                axisLine: { show: false },
              },
            ],
            series: chartRatio.map((item) => {
              const ratiosArr = res.data.data
                .map((it) => it.chartRatios)
                .flat();
              this.arr = ratiosArr.filter((it) => it.name == item.name);
              return {
                name: item.name,
                type: "bar",
                stack: "ad",
                barMaxWidth: 40,
                data: this.arr.map((it) => it.proportion),
              };
            }),
          };
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getExamObj() {
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
      this.getGroup();
    },

    async dropdownChange(command) {
      if (command === "detail") {
        const { subjectId } = this.$route.query;
        this.dialogVisible = true;
        if (subjectId == "0") {
          this.exportForm.expTopSectionTotal = 0;
          this.exportForm.expTopSectionProportion = 0;
        }
      } else {
        const { statId, subjectId } = this.$route.query;

        let exportParams = {};
        for (let key in this.visibleForm) {
          let newKey = "exp" + key.slice(0, 1).toUpperCase() + key.slice(1);
          exportParams[newKey] = Number(this.visibleForm[key]);
        }
        const res = await exportindicatorcompare({
          statId: Number(statId),
          subjectId: subjectId,
          resEleccombId: this.resEleccombId,
          ...this.search,
          ...exportParams,
        });
        if (res.msg) {
          this.$message({
            type: "warning",
            message: res.msg,
            showClose: true,
          });
        } else {
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: res.data.data.url,
              name: res.data.data.name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
        }
      }
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getData();
    },
    handleClick(item) {
      this.resEleccombId = item.id;
      this.getData();
    },
    filterHandler(value, row, column) {
      // 表格筛选
      const property = column["property"];
      return row[property] === value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./filter.module.scss";

.filter-index {
  box-sizing: border-box;
  padding: 12px 18px;
  background-color: #fff;
  border-radius: 4px;
  margin: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 18px;
    grid-row-gap: 18px;

    .category {
      height: 62px;
      background: #fbfcfd;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 8px 18px;
      grid-gap: 18px;

      .category-title {
        color: #0a1119;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .el-checkbox {
        margin-bottom: 0;
        margin-right: 0;
      }

      .el-checkbox + .el-checkbox {
        margin-left: 18px;
      }
    }
  }
}

.GradeRank {
  box-sizing: border-box;
  padding-top: 18px;
  .table-content {
    box-sizing: border-box;
    padding: 18px;
    border-radius: 4px;
    background-color: #fff;
    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;
      .title {
        color: #2474ed;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .subjects {
      border: 1px solid #ebecee;
      box-sizing: border-box;
      padding: 12px 18px;
      border-bottom: none;
      grid-column-gap: 18px;
      grid-row-gap: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px 4px 0px 0px;
      .subject-item {
        height: 32px;
        border-radius: 4px;
        border: 1px solid #d5d6db;
        line-height: 32px;
        text-align: center;
        box-sizing: border-box;
        padding: 0 18px;
        cursor: pointer;
      }
      .active {
        border-color: #2474ed;
        color: #2474ed;
      }
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.export-box {
  &-title {
    font-size: 14px;
    color: #0a1119;
    margin: 18px 0;
  }
}
.option {
  margin-bottom: 18px;
  border: 1px solid #ebecee;
  &-title {
    background: #f5f7fa;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #ebecee;
    height: 44px;
    box-sizing: border-box;
    line-height: 44px;
    padding: 0 18px;
  }
  .checks {
    height: 44px;
    line-height: 44px;
    box-sizing: border-box;
    padding: 0 18px;
    .el-checkbox {
      margin-bottom: 0;
      margin-right: 0;
    }
    .el-checkbox + .el-checkbox {
      margin-left: 18px;
    }
  }
}

::v-deep .el-dialog__footer {
  text-align: center !important;
}
::v-deep .el-icon-arrow-down:before {
  content: "";
  font-size: 18px;
}
.charts {
  height: 506px;
  width: 100%;
}
.allowClick {
  color: #2474ed;
  cursor: pointer;
}
</style>
